// extracted by mini-css-extract-plugin
export var contentCard = "bohema-start-module--content-card--470b8";
export var contentDescription = "bohema-start-module--content-description--4e905";
export var contentImage = "bohema-start-module--content-image--00e7e";
export var contentSubtitle = "bohema-start-module--content-subtitle--11eba";
export var contentTitle = "bohema-start-module--content-title--5dd28";
export var contentWrapper = "bohema-start-module--content-wrapper--0457d";
export var description = "bohema-start-module--description--332ff";
export var grid = "bohema-start-module--grid--751b7";
export var moreButton = "bohema-start-module--more-button--0c50c";
export var overwrite = "bohema-start-module--overwrite--fd627";
export var school = "bohema-start-module--school--271b4";
export var section = "bohema-start-module--section--0c0d2";
export var shop = "bohema-start-module--shop--45fff";
export var subtitle = "bohema-start-module--subtitle--b028a";
export var theater = "bohema-start-module--theater--b4ef4";
export var title = "bohema-start-module--title--3a814";