import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

import {
    overwrite,
    section as sectionClass,
    grid,
    title as titleClass,
    description as descriptionClass,
    subtitle as subtitleClass,
    contentWrapper,
    contentCard,
    contentImage,
    contentTitle,
    contentDescription,
    contentSubtitle,
    moreButton,
    theater,
    school,
    shop
} from './bohema-start.module.scss';

import { ISection, ISectionContentButton } from '../../models/section.model';

import Section from '../hoc/section';
import useTranslations from "../../hooks/use-translations";
import {Link} from "gatsby";

const theaterSrc = '../../assets/images/bohema-theater.png';
const schoolSrc = '../../assets/images/bohema-school.png';
const shopSrc = '../../assets/images/bohema-shop.png';

interface IBohemaStartSection extends ISection {
    content: {
        links: {
            linkTheatre: string;
            linkDance: string;
            linkShop: string;
        };
    };
}

interface IBohemaStartProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IBohemaStartSection;
}

const BohemaStart: React.FC<IBohemaStartProps> = ({section, className, TitleTag}) => {

    const t = useTranslations('BohemaStart');
    const { sectionId, content, css, style } = section;
    const { texts, buttons } = content;
    const [title, subtitle, description] = texts;
    const button: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionClass} ${className} ${overwrite}`}
            classes={{
                container: grid,
                title: titleClass,
                description: descriptionClass,
                subtitle: subtitleClass,
            }}
            TitleTag={TitleTag}
            title={title}
            subtitle={subtitle}
            description={description}
            buttonText={button.text}
            buttonUrl={button.url}
            css={css}
            style={style}
        >
            <div className={contentWrapper}>
                <Link to={section.content.links.linkTheatre} className={`${contentCard} ${theater}`}>
                    <StaticImage className={contentImage} src={theaterSrc} alt={'test'} />
                    <span className={contentSubtitle}>{t.theatre.subtitle}</span>
                    <span className={contentTitle}>{t.theatre.title}</span>
                    <p className={contentDescription}>{t.theatre.description}</p>
                    <span className={moreButton}>{t.more}</span>
                </Link>
                <Link to={section.content.links.linkDance} className={`${contentCard} ${school}`}>
                    <StaticImage className={contentImage} src={schoolSrc} alt={'test'} />
                    <span className={contentSubtitle}>{t.school.subtitle}</span>
                    <span className={contentTitle}>{t.school.title}</span>
                    <p className={contentDescription}>{t.school.description}</p>
                    <span className={moreButton}>{t.more}</span>
                </Link>
                <Link to={section.content.links.linkShop} className={`${contentCard} ${shop}`}>
                    <StaticImage className={contentImage} src={shopSrc} alt={'test'} />
                    <span className={contentSubtitle}>{t.shop.subtitle}</span>
                    <span className={contentTitle}>{t.shop.title}</span>
                    <p className={contentDescription}>{t.shop.description}</p>
                    <span className={moreButton}>{t.more}</span>
                </Link>
            </div>
        </Section>
    );
}

export default BohemaStart;